import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faMicrophone, faPaperPlane, faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { Workflow, Message } from "./types";
import axios from "axios";
import { MicVAD, utils } from "@ricky0123/vad-web";
// import { interpolateInferno } from "d3-scale-chromatic";

axios.defaults.withCredentials = true;

const ProcessingMessage = ({ sender }: { sender?: string }) => {
    return (
        <div className={`flex ${sender === "user" ? "justify-end" : "justify-start"} animate-pulse`} >
            <div className={`max-w-[70%] min-w-[40%] rounded-lg p-4 bg-blue-100 text-blue-500`}>
                {/* Placeholder for message text (longer line) */}
                <div className="h-6 rounded bg-blue-300 mb-3 w-full"></div>

                {/* Placeholder for audio button with play icon */}
                <div className="mt-3 flex items-center space-x-3">
                    <div className="w-8 h-8 rounded-full bg-blue-300"></div> {/* Placeholder for audio button */}
                    <div className="h-3 rounded bg-blue-300 flex-1"></div> {/* Long placeholder for audio line */}
                </div>

                {/* Placeholder for timestamp */}
                <div className="text-xs mt-3 opacity-70">
                    <div className="h-2 rounded bg-blue-300 w-16"></div> {/* Placeholder for timestamp */}
                </div>
            </div>
        </div>
    );
};

const MessageComponent = ({ message, togglePlay }: { message: Message; togglePlay: (id: string) => void; }) => {
    return (
        <div key={message.id} className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"}`}>
            <div className={`max-w-[70%] rounded-lg p-4 ${message.sender === "user" ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-900"}`}>
                <p>{message.text}</p>
                {message.audioUrl && (
                    <div className="mt-2 flex items-center space-x-2">
                        {/* <button
                            onClick={() => togglePlay(message.id)}
                            className="!rounded-button text-sm px-3 py-1 bg-white text-gray-900"
                        >
                            <FontAwesomeIcon icon={message.isPlaying ? faPause : faPlay} />
                        </button> */}
                        <audio id={`audio-${message.id}`} src={message.audioUrl} controls className="" />
                    </div>
                )}
                <div className="text-xs mt-1 opacity-70">{message.timestamp.toLocaleTimeString()}</div>
            </div>
        </div>
    );
};

const ChatComponentv2 = () => {
    const workflow: Workflow = useLocation().state?.selectedWorkflow as Workflow;
    const data: any = useLocation().state?.formData;
    const navigate = useNavigate();

    const [messages, setMessages] = useState<Message[]>([]);
    const [inputText, setInputText] = useState("");
    const [isRecording, setIsRecording] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isInitialised, setIsInitialised] = useState(false);
    const [conversationId, setConversationId] = useState("");
    const [isEnd, setIsEnd] = useState(false);
    const [processingInputType, setProcessingInputType] = useState<"user" | "agent">("agent"); // Add isProcessing state
    const speechTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const audioChunksRef = useRef<Blob[]>([]);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const [autoplayEnabled, setAutoplayEnabled] = useState(true);
    const latestRequestIdRef = useRef<string>("");
    const vadRef = useRef<MicVAD | null>(null);

    
    
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [messages]);

    useEffect(() => {
        async function initVAD() {
          try {
            vadRef.current = await MicVAD.new({
              model: "v5",
            //   positiveSpeechThreshold: 0.4,
              positiveSpeechThreshold: 0.4,
              negativeSpeechThreshold: 0.4,
            //   minSpeechFrames: 15,
              minSpeechFrames: 15,
              preSpeechPadFrames: 30,
              onSpeechStart: () => {
                console.log("SPEECH START")
                speechTimeoutRef.current = setTimeout(() => {
                    handleSpeechStart();
                  }, 1250);
              },
              onVADMisfire() {
                console.log("VAD Misfire");
                if (speechTimeoutRef.current) {
                    clearTimeout(speechTimeoutRef.current);
                    speechTimeoutRef.current = null; // Clear reference
                }
                // Clear vad misfire
              },
              onSpeechEnd: (audio) => {
                const wavBuffer = utils.encodeWAV(audio);
                const audioBlob = new Blob([wavBuffer], { type: "audio/wav" });
                handleSendAudioMessage(audioBlob);
              },
            });
          } catch (e) {
            console.error("VAD initialization failed:", e);
          }
        }
        initVAD();
      }, []);


    if (!workflow) {
        console.log("No workflow selected. Redirecting to home page...");
        navigate("/");
        return null
    }


    const togglePlay = (id: string) => {
        setMessages((prevMessages) =>
            prevMessages.map((msg) =>
                msg.audioUrl ? { ...msg, isPlaying: msg.id === id ? !msg.isPlaying : false } : msg
            )
        );

        const audioElement = document.getElementById(`audio-${id}`) as HTMLAudioElement;
        if (audioElement) {
            if (audioElement.paused) {
                audioElement.play();
            } else {
                audioElement.pause();
            }
        }
    };

    const stopAllPlaying = () => {
        const audioElements = document.getElementsByTagName("audio");
        for (let i = 0; i < audioElements.length; i++) {
            audioElements[i].pause();
            // audioElements[i].currentTime = 0;
        }
    };

    const handleSpeechStart = async () => {
        console.log("Handle speech start")
        stopAllPlaying();
    }

    const handleSendTextMessage = async (text: string, audio_url?: string) => {
        setProcessingInputType("user");
        setIsProcessing(true);
        const requestId = Date.now().toString();
        latestRequestIdRef.current = requestId;
        console.log(requestId);

        const sentMessage: Message = {
            id: Date.now().toString(),
            text: text,
            sender: "user",
            audioUrl: audio_url || "",
            timestamp: new Date(),
        };

        setMessages((prev) => [...prev, sentMessage]);
        setInputText("");
        setProcessingInputType("agent");

        try {
            const response = await fetch("/backend/chat", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ user_input: text, audio_url: audio_url }),
                credentials: "include",
                mode: 'cors'
            });

            if (!response.ok) throw new Error("Failed to fetch agent response");



            const agentResponse = await response.json();
            const conv_id = response.headers.get("x-conversation-id");
            setConversationId(conv_id || "");

            if (latestRequestIdRef.current !== requestId) {
                console.log(requestId, latestRequestIdRef.current);
                console.log("Received response for outdated request. Ignoring...");
                return;
            }

            const receivedMessage: Message = {
                id: Date.now().toString(),
                text: agentResponse.message,
                sender: "agent",
                timestamp: new Date(),
                audioUrl: agentResponse.response_audio_url || "",
            };
            setMessages((prev) => [...prev, receivedMessage]);
            if (agentResponse.is_end) {
                vadRef.current?.destroy();
                stopAllPlaying();
                setIsEnd(true);
            }
            
            playNewlyCreatedMessage(receivedMessage);
            await ackMessage(agentResponse.id);
            setIsProcessing(false);

        } catch (error) {
            console.error("Error fetching agent response:", error);
            setIsProcessing(false);
        }
    };

    const ackMessage = async (id: string) => {
        const tempResponse = await fetch("/backend/update-msg-status", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ message_id: id}),
            credentials: "include",
            mode: 'cors'
        });
        console.log(tempResponse);
    }

    const handleSendAudioMessage = async (audioBlob: Blob) => {
        setProcessingInputType("user");
        setIsProcessing(true);

        const formData = new FormData();
        formData.append("audio", audioBlob);

        try {

            const response = await fetch("/backend/user-audio-transcribe", {
                method: "POST",
                body: formData,
                credentials: "include",
                mode: 'cors'
            });

            if (!response.ok) throw new Error("Failed to fetch agent response");

            const jsonResponse = await response.json();

            handleSendTextMessage(jsonResponse.transcript, jsonResponse.audio_url);
        } catch (error) {
            console.error("Error processing audio message:", error);
            setIsProcessing(false);
        }
    };

    const handleStartRecording = () => {
        setIsRecording(true);
        if (vadRef.current) {
            vadRef.current.start();
        }
        // navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        //     const mediaRecorder = new MediaRecorder(stream);
        //     mediaRecorderRef.current = mediaRecorder;
        //     mediaRecorder.ondataavailable = (event) => {
        //         audioChunksRef.current.push(event.data);
        //     };
        //     mediaRecorder.onstop = () => {
        //         const audioBlob = new Blob(audioChunksRef.current, { type: "audio/mp3" });
        //         handleSendAudioMessage(audioBlob);
        //         audioChunksRef.current = [];
        //     };
        //     mediaRecorder.start();
        // });
    };

    const handleStopRecording = () => {
        setIsRecording(false);
        if (vadRef.current) {
            vadRef.current.pause();
        }
        // if (mediaRecorderRef.current) {
        //     mediaRecorderRef.current.stop();
        // }
    };

    const playNewlyCreatedMessage = (message: Message) => {
        console.log("Playing new message");
        stopAllPlaying();
        if (autoplayEnabled && message.audioUrl) {
            setTimeout(() => {
                const audioElement = document.getElementById(`audio-${message.id}`) as HTMLAudioElement;
                if (audioElement) {
                    const playPromise = audioElement.play();
                    if (playPromise !== undefined) {
                        playPromise.catch(error => console.warn("Autoplay blocked:", error));
                    }
                }
            }, 100);
        }
    }


    const handleInitialMessage = async (props: any) => {
        setIsInitialised(true);
        setIsProcessing(true);
        props["workflow_id"] = workflow.id;
        try {
            const response = await fetch("/backend/start", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(props),
                credentials: "include",
                mode: 'cors'
            });
            console.log(response);

            if (!response.ok) throw new Error("Failed to fetch initial message");

            const agentResponse = await response.json();
            const conv_id = response.headers.get("x-conversation-id");
            setConversationId(conv_id || "");

            const receivedMessage: Message = {
                id: Date.now().toString(),
                text: agentResponse.message,
                sender: "agent",
                timestamp: new Date(),
                audioUrl: agentResponse.response_audio_url || "",
            };
            setIsProcessing(false);
            setMessages([receivedMessage]);
            playNewlyCreatedMessage(receivedMessage);
            await ackMessage(agentResponse.id);
        } catch (error) {
            console.error("Error fetching initial message:", error);
        }
    };
    if (isInitialised === false) {
        handleInitialMessage(data);
    }

    return (
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="border-b px-6 py-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                        <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                        <span className="font-medium">{workflow.display_name} : {conversationId} </span>
                    </div>
                    {/* <div>
                        <label className="flex items-center space-x-2">
                            <input type="checkbox" onChange={(e) => { 
                                console.log(autoplayEnabled);
                                setAutoplayEnabled(!autoplayEnabled);
                            }} />
                            <span>Autoplay Audio</span>
                        </label>
                    </div> */}
                    <button
                        className="p-1.5 rounded-full bg-gray-200 text-gray-600 text-lg transition duration-200 ease-in-out 
               hover:bg-red-600 hover:text-white active:bg-red-800 focus:outline-none focus:ring focus:ring-red-300 
               shadow-md hover:shadow-lg"
                        onClick={() => navigate("/")}
                        aria-label="Close"
                    >
                        <FontAwesomeIcon icon={faTimes} className="w-7 h-5" />
                    </button>





                </div>
            </div>
            <div ref={chatContainerRef} className="h-[600px] overflow-y-auto px-6 py-4 space-y-4">
                {messages.map((message) => (
                    <MessageComponent key={message.id} message={message} togglePlay={togglePlay} />
                ))}

                {isProcessing && <ProcessingMessage sender={processingInputType} />}

            </div>

            <div className="border-t px-6 py-4">
                {isEnd && <div className="text-center text-gray-500">Chat has ended.
                    <div className="mt-4">
                        <button
                            onClick={() => navigate("/feedback")}
                            className="text-blue-600 hover:underline"
                        >
                            Provide Feedback
                        </button>
                </div>
                </div>}
                {!isEnd &&
                    <div className="flex items-center space-x-4">
                        <button
                            onClick={isRecording ? handleStopRecording : handleStartRecording}
                            className={`!rounded-button p-2 ${isRecording ? "bg-red-500 text-white animate-pulse" : "bg-gray-100 text-gray-500 hover:bg-gray-200"}`}
                        >
                            <FontAwesomeIcon icon={faMicrophone} />
                            {isRecording && <span className="ml-2">Recording...</span>}
                        </button>
                        <input
                            type="text"
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            placeholder="Type your message..."
                            disabled={isRecording}
                            className="flex-1 px-4 py-2 border border-gray-300 rounded-full focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
                            onKeyDown={(e) => e.key === 'Enter' && !isRecording && inputText.trim() && handleSendTextMessage(inputText)}
                        />
                        <button
                            onClick={() => handleSendTextMessage(inputText)}
                            disabled={!inputText.trim() || isRecording}
                            className="!rounded-button bg-blue-600 text-white p-2 disabled:opacity-50 
               shadow-md hover:shadow-lg transition duration-200 ease-in-out 
               hover:bg-blue-700 active:bg-blue-800"
                        >
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    </div>
                }
            </div>
        </div>
    );

};


export default ChatComponentv2;

import React, { useState, useEffect, useRef } from 'react';
import { MessageComponent } from './Message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faPhoneSlash, faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Message, Workflow } from '../types';
import { MicVAD, utils } from "@ricky0123/vad-web";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import FeedbackDrawer from "../Feedback"; // Adjust path as needed



const DuplexV2: React.FC = () => {
    const navigate = useNavigate();
    const workflow: Workflow = useLocation().state?.selectedWorkflow as Workflow;
    const data: any = useLocation().state?.formData;

    const [isMuted, setIsMuted] = useState(true);
    const [isInitialProcessingDone, setIsInitalProcessingDone] = useState(false);
    const [volume, setVolume] = useState(80);
    const [showVolumeSlider, setShowVolumeSlider] = useState(false);
    const [callDuration, setCallDuration] = useState(0);
    const [isAgentSpeaking, setIsAgentSpeaking] = useState(false);
    const [isUserSpeaking, setIsUserSpeaking] = useState(false);
    const [isAgentMuted, setIsAgentMuted] = useState(false);

    const [conversationId, setConversationId] = useState("");
    const [isEnd, setIsEnd] = useState(false);
    const [isInitialised, setIsInitialised] = useState(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);

    const vadRef = useRef<MicVAD | null>(null);
    const speechTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const latestRequestIdRef = useRef<string>("");
    const [isMicDestroyed , setisMicDestroyed]  = useState(false);

    // const feedbackIcon = (
      
    //   <div
    //     style={{
    //       position: "fixed",
    //       bottom: "20px",
    //       right: "20px",
    //       cursor: "pointer",
    //       zIndex: 1000,
    //       backgroundColor: "#007bff",
    //       color: "white",
    //       padding: "10px",
    //       borderRadius: "50%",
    //       boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
    //       transition: "transform 0.2s, background-color 0.2s",
    //     }}
    //     onClick={() => navigate("/feedback")}
    //     title="Give Feedback"
    //     aria-label="Feedback"
    //     onMouseEnter={(e) => {
    //       e.currentTarget.style.backgroundColor = "#0056b3";
    //       e.currentTarget.style.transform = "scale(1.1)";
    //     }}
    //     onMouseLeave={(e) => {
    //       e.currentTarget.style.backgroundColor = "#007bff";
    //       e.currentTarget.style.transform = "scale(1)";
    //     }}
    //   >
    //     <FontAwesomeIcon icon={faCommentDots} size="2x" />
    //   </div>
    // );

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [messages]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCallDuration(prev => prev + 1);
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        async function initVAD() {
            try {
                vadRef.current = await MicVAD.new({
                    model: "v5",
                    //   positiveSpeechThreshold: 0.4,
                    positiveSpeechThreshold: 0.5,
                    negativeSpeechThreshold: 0.35,
                    minSpeechFrames: 5,
                    preSpeechPadFrames: 30,
                    onSpeechStart: () => {
                        // console.log("SPEECH START")
                        setIsUserSpeaking(true);
                        speechTimeoutRef.current = setTimeout(() => {
                            handleSpeechStart();
                        }, 1250);
                    },
                    onFrameProcessed: (probabilities, frame) => {
                        // setAudioLevel(probabilities.isSpeech);
                        // setUserAudioLevelData(Array.from(frame))
                    },
                    onVADMisfire() {
                        setIsUserSpeaking(false);
                        console.log("VAD Misfire");
                        if (speechTimeoutRef.current) {
                            clearTimeout(speechTimeoutRef.current);
                            speechTimeoutRef.current = null; // Clear reference
                        }
                        // Clear vad misfire
                    },
                    onSpeechEnd: (audio) => {
                        const wavBuffer = utils.encodeWAV(audio);
                        const audioBlob = new Blob([wavBuffer], { type: "audio/wav" });
                        handleSendAudioMessage(audioBlob);
                    },
                });
            } catch (e) {
                console.error("VAD initialization failed:", e);
            }
        }
        initVAD();
    }, []);



    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        let animationFrameId: number;
        const draw = () => {
            if (!ctx || !canvas) return;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const width = canvas.width;
            const height = canvas.height;
            const waves = 3;
            const points = 100;
            ctx.lineWidth = 2;
            ctx.lineCap = 'round';
            for (let wave = 0; wave < waves; wave++) {
                ctx.beginPath();
                const gradient = ctx.createLinearGradient(0, height / 2 - 50, 0, height / 2 + 50);
                gradient.addColorStop(0, `rgba(76, 175, 80, ${0.8 - wave * 0.3})`);
                gradient.addColorStop(1, `rgba(76, 175, 80, ${0.2 - wave * 0.15})`);
                ctx.strokeStyle = gradient;
                for (let i = 0; i <= points; i++) {
                    const x = (width * i) / points;
                    const y = height / 2 +
                        Math.sin(i / 10 + Date.now() * 0.002 + wave * Math.PI * 0.5) * 30 +
                        Math.sin(i / 15 + Date.now() * 0.003 - wave * Math.PI * 0.5) * 20;
                    i === 0 ? ctx.moveTo(x, y) : ctx.lineTo(x, y);
                }
                ctx.stroke();
            }
            animationFrameId = requestAnimationFrame(draw);
        };
        draw();
        return () => cancelAnimationFrame(animationFrameId);
    }, []);

    const formatTime = (seconds: number): string => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const stopAllPlaying = () => {
        const audioElements = document.getElementsByTagName("audio");
        for (let i = 0; i < audioElements.length; i++) {
            audioElements[i].pause();
            // audioElements[i].currentTime = 0;
        }
    };

    const handleSpeechStart = async () => {
        console.log("Handle speech start")
        stopAllPlaying();
        setIsUserSpeaking(true);
    };

    const handleSendAudioMessage = async (audioBlob: Blob) => {

        const formData = new FormData();
        formData.append("audio", audioBlob);

        try {

            const response = await fetch("/backend/user-audio-transcribe", {
                method: "POST",
                body: formData,
                credentials: "include",
                mode: 'cors'
            });

            if (!response.ok) throw new Error("Failed to fetch agent response");

            const jsonResponse = await response.json();

            handleSendTextMessage(jsonResponse.transcript, jsonResponse.audio_url);
        } catch (error) {
            console.error("Error processing audio message:", error);
        }
    };

    const playAgentMessage = (message: Message): Promise<void> => {
        return new Promise((resolve) => {
            stopAllPlaying();
            setIsAgentSpeaking(true);
    
            if (!message.audioUrl) {
                console.warn("No audio URL provided for message:", message.id);
                return resolve(); // Resolve immediately if no audio URL
            }
    
            setTimeout(() => {
                const audioElement = document.getElementById(`audio-${message.id}`) as HTMLAudioElement;
    
                if (!audioElement) {
                    console.warn("Audio element not found for message:", message.id);
                    return resolve(); // Resolve if the element does not exist
                }
    
                if (isAgentMuted) {
                    console.warn("Agent is muted. Skipping playback.");
                    return resolve();
                }
    
                // Ensure the audio element is loaded before playing
                audioElement.load();
                audioElement.oncanplaythrough = () => {
                    console.log("Audio ready to play:", message.audioUrl);
                    const playPromise = audioElement.play();
    
                    if (playPromise !== undefined) {
                        playPromise
                            .then(() => {
                                console.log("Audio is playing:", message.id);
                            })
                            .catch((error) => {
                                console.warn("Autoplay blocked:", error);
                                resolve(); // Resolve if autoplay is blocked
                            });
                    }
                };
    
                // Resolve when playback ends
                audioElement.onended = () => {
                    console.log("Audio finished playing:", message.id);
                    resolve();
                };
            }, 100);
        });
    };
    

    const toggleAgentMute = () => {
        setIsAgentMuted(prevState => {
            console.log("Toggle agent mute", !prevState);
            return !prevState;
        });
    };


    const handleSendTextMessage = async (text: string, audio_url?: string) => {
        // setProcessingTypeType("user");
        // setIsAgentProcessing(true);

        const requestId = Date.now().toString();
        latestRequestIdRef.current = requestId;
        console.log(requestId);

        const sentMessage: Message = {
            id: Date.now().toString(),
            text: text,
            sender: "user",
            audioUrl: audio_url || "",
            timestamp: new Date(),
        };

        setMessages((prev) => [...prev, sentMessage]);
        // setProcessingInputType("agent");

        try {
            const response = await fetch("/backend/chat", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ user_input: text, audio_url: audio_url }),
                credentials: "include",
                mode: 'cors'
            });

            if (!response.ok) throw new Error("Failed to fetch agent response");



            const agentResponse = await response.json();
            const conv_id = response.headers.get("x-conversation-id");
            setConversationId(conv_id || "");

            if (latestRequestIdRef.current !== requestId) {
                console.log(requestId, latestRequestIdRef.current);
                console.log("Received response for outdated request. Ignoring...");
                return;
            }

            const receivedMessage: Message = {
                id: Date.now().toString(),
                text: agentResponse.message,
                sender: "agent",
                timestamp: new Date(),
                audioUrl: agentResponse.response_audio_url || "",
            };
            setMessages((prev) => [...prev, receivedMessage]);

            if(agentResponse.is_end && !isMicDestroyed){
                destroyMic();
            }
            
            await playAgentMessage(receivedMessage);
            await ackMessage(agentResponse.id);
            //   setIsAgentProcessing(false);
            
            if (agentResponse.is_end) {
                endCall();
            }
        } catch (error) {
            console.error("Error fetching agent response:", error);
            //   setIsAgentProcessing(false);
        }
    };

    const toggleRecording = async () => {
        console.log("Toggle recording", isMuted);
        if (isMuted) {
            vadRef.current?.start();
        } else {
            vadRef.current?.pause();
        }
        setIsMuted((prev) => !prev);
    }

    const ackMessage = async (id: string) => {
        const tempResponse = await fetch("/backend/update-msg-status", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ message_id: id }),
            credentials: "include",
            mode: 'cors'
        });
        console.log(tempResponse);
    };

    const destroyMic = () => {
        if (vadRef.current) {
            vadRef.current.destroy();
            vadRef.current = null;
        }
        setIsMuted(true);
        setisMicDestroyed(true);
    };
    
    const endCall = async () => {
        if (isEnd) return;
        if (!isMicDestroyed) {
            destroyMic();
        }
        stopAllPlaying();
        setIsEnd(true);
        try {
            const tempResponse = await fetch("/backend/end", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: '{}',
                credentials: "include",
                mode: 'cors'
            });
            console.log(tempResponse);
        } catch (error) {
            console.error("Error ending call:", error);
        }
    };


    const handleInitialMessage = async (props: any) => {
        setIsInitialised(true);
        //   setIsAgentProcessing(true);
        props["workflow_id"] = workflow.id;
        try {
            const response = await fetch("/backend/start", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(props),
                credentials: "include",
                mode: 'cors'
            });
            console.log(response);

            if (!response.ok) throw new Error("Failed to fetch initial message");

            const agentResponse = await response.json();
            const conv_id = response.headers.get("x-conversation-id");
            setConversationId(conv_id || "");

            const receivedMessage: Message = {
                id: Date.now().toString(),
                text: agentResponse.message,
                sender: "agent",
                timestamp: new Date(),
                audioUrl: agentResponse.response_audio_url || "",
            };
            // setIsAgentProcessing(false);
            setMessages([receivedMessage]);
            playAgentMessage(receivedMessage);

            await ackMessage(agentResponse.id);
            setIsInitalProcessingDone(true);
        } catch (error) {
            console.error("Error fetching initial message:", error);
        }
    };

    const redirectToHome = () => {
        navigate("/");
    }


    if (!isInitialised) {
        setIsInitialised(true);
        handleInitialMessage(data);
        // handleStartRecording();
    }


    return (
        <div className="relative h-screen bg-gradient-to-b from-[#121212] to-[#1E1E1E] text-white">
                        {!isInitialProcessingDone && (

<div className="fixed inset-0 flex items-center justify-center bg-black/80 z-50">
<div className="flex items-center justify-center min-h-screen">
  <div className="flex space-x-2 animate-pulse">
    <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
    <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
    <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
  </div>
</div>
</div>

)}
            {isEnd && (

                <div className="fixed inset-0 flex items-center justify-center bg-black/80 z-50">
                    <button
                        onClick={redirectToHome}
                        className="px-6 py-3 bg-[#1E3A8A] text-white text-lg font-semibold rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:bg-[#1E40AF]"
                    >
                        Chat Ended
                    </button>
                    {/* Feedback Drawer Component */}
                    <FeedbackDrawer conversationId={conversationId} />
                
                </div>

            )}
            <div className="fixed top-0 w-full bg-black/70 p-4 z-10 flex justify-between">
                <span className="text-sm">{workflow.display_name}</span>
                <span className="text-sm">{formatTime(callDuration)}</span>
            </div>


            <div className="pt-12 pb-24 h-full flex flex-col">
                <div className="h-1/2 flex flex-col items-center justify-center relative">
                    <img
                        src="https://static.readdy.ai/image/b96376c1db9f62c52da78c2504af8261/a78ea042d989ed85554932bc9e612c79.png"
                        alt="Hyperface Logo"
                        className="absolute top-8 h-6 z-10"
                    />
                    <canvas ref={canvasRef} className="w-full h-full" width={375} height={160} style={{ filter: 'blur(1px)' }} />
                    {(isUserSpeaking || isAgentSpeaking) && (
                        <div className="bg-black/30 px-4 py-2 rounded-full mt-4 flex items-center">
                            <div className="w-2 h-2 rounded-full bg-green-500 mr-2 animate-pulse"></div>
                            <span>{isUserSpeaking ? "You are" : 'Agent is'} speaking</span>
                        </div>
                    )
                    }
                    {conversationId &&
                        (<div className="bg-black/30 px-4 py-2 rounded-full mt-4 flex items-center">
                            <span>{conversationId}</span>
                        </div>
                        )}
                </div>
                <div ref={chatContainerRef} className="h-1/2 bg-black/20 backdrop-blur-sm overflow-y-auto p-4 space-y-4">
                    {messages.map((msg, index) => (
                        <MessageComponent key={index} message={msg} handleAudionEnd={() => { setIsAgentSpeaking(false) }} isMuted={isAgentMuted} />
                    ))}
                </div>
            </div>
            <div className="fixed bottom-0 w-full bg-grey-600/20 backdrop-blur-xs p-6 flex justify-center items-center gap-4 ">
                <div className="relative">
                    <button onClick={() => toggleAgentMute()} className={`w-14 h-14 rounded-full flex items-center justify-center ${isAgentMuted ? 'bg-red-500 ring-red-500/30' : 'bg-gray-700 ring-gray-500/30'}`}>
                        {isAgentMuted ? (
                            <FontAwesomeIcon icon={faVolumeMute} className="text-xl animate-pulse" />

                        ) : (
                            <FontAwesomeIcon icon={faVolumeUp} className="text-xl sm:text-2xl" />
                        )}
                    </button>
                    {/* {showVolumeSlider && (
                        <div className="absolute bottom-20 left-1/2 -translate-x-1/2 w-12 h-40 bg-gray-800/90 p-3 rounded-2xl shadow-lg flex flex-col items-center">
                            <span className="text-xs mb-2">{volume}%</span>
                            <input type="range" min="0" max="100" value={volume} onChange={e => setVolume(parseInt(e.target.value))} className="w-32 -rotate-90 appearance-none bg-transparent" />
                        </div>
                    )} */}
                </div>
                <button className="w-16 h-16 bg-red-500 rounded-full flex items-center justify-center">
                    <FontAwesomeIcon onClick={endCall} icon={faPhoneSlash} className="text-xl" />
                </button>
                <button
                    onClick={() => toggleRecording()}
                    className={`w-14 h-14 rounded-full flex items-center justify-center ring-4 transition-all duration-200
    ${isMuted ? 'bg-red-500 ring-red-500/30' : 'bg-gray-700 ring-gray-500/30 animate-pulse'}`}>
                    {isMuted ? (
                        <FontAwesomeIcon icon={faMicrophoneSlash} className="text-xl sm:text-2xl" />
                    ) : (
                        <FontAwesomeIcon icon={faMicrophone} className="text-xl animate-pulse" />
                    )}
                </button>

            </div>
        </div>
    );
};

export default DuplexV2;

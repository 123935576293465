// import React, { useState } from "react";
// import axios from "axios";

// const Feedback: React.FC = () => {
//     const [feedback, setFeedback] = useState<string>("");
//     const [responseMessage, setResponseMessage] = useState<string>("");
//     const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

//     const handleFeedbackSubmit = async () => {
//         if (!feedback.trim()) {
//             setResponseMessage("Please enter your feedback.");
//             return;
//         }

//         try {
//             const response = await axios.post("/backend/user-feedback", { feedback });
//             setResponseMessage(response.data.message || "Thank you for your feedback!");
//             setIsSubmitted(true); // Set the form as submitted
//             setFeedback(""); // Clear the textarea after submission
//         } catch (error: any) {
//             setResponseMessage(
//                 error.response?.data?.error || "Failed to submit feedback. Please try again."
//             );
//         }
//     };

//     return (
//         <div className="p-6">
//             {isSubmitted ? (
//                 <p className="text-green-600">{responseMessage}</p>
//             ) : (
//                 <>
//                     <h2 className="text-2xl font-semibold mb-4">Feedback</h2>
//                     <textarea
//                         className="w-full p-2 border rounded-md"
//                         placeholder="Enter your feedback..."
//                         value={feedback}
//                         onChange={(e) => setFeedback(e.target.value)}
//                     ></textarea>
//                     <button
//                         className="mt-2 p-2 bg-blue-500 text-white rounded-md"
//                         onClick={handleFeedbackSubmit}
//                     >
//                         Submit Feedback
//                     </button>
//                     {responseMessage && <p className="mt-2 text-red-600">{responseMessage}</p>}
//                 </>
//             )}
//         </div>
//     );
// };

// export default Feedback;
// import React, { useState, useEffect } from "react";
// import axios from "axios";

// interface FeedbackProps {
//     conversationId: string;
// }

// const FeedbackDrawer: React.FC<FeedbackProps> = ({ conversationId = "Unknown" }) => {
//     const [showFeedbackForm, setShowFeedbackForm] = useState<boolean>(false);
//     const [feedback, setFeedback] = useState<string>("");
//     const [responseMessage, setResponseMessage] = useState<string>("");
//     const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
//     const [llmUsed, setLlmUsed] = useState<string>("Unknown");
//     const [ttsUsed, setTtsUsed] = useState<string>("Unknown");
//     const [sttUsed, setSttUsed] = useState<string>("Unknown");

//     // useEffect(() => {
//     //     const fetchConfigAndId = async () => {
//     //         try {
//     //             const configResponse = await axios.get("/backend/config");
//     //             setLlmUsed(configResponse.data.llmUsed || "Unknown");
//     //             setTtsUsed(configResponse.data.ttsUsed || "Unknown");
//     //             setSttUsed(configResponse.data.sttUsed || "Unknown");
    
//     //             const idResponse = await axios.get("/backend/conversation-id");
//     //             setConversationId(idResponse.data.conversation_id || "Unknown");
//     //         } catch (error) {
//     //             console.error("Error fetching configuration or conversation ID:", error);
//     //         }
//     //     };
    
//     //     fetchConfigAndId();
//     // }, []);
    
//     useEffect(() => {
//         const fetchConfigAndId = async () => {
//             try {
//                 const configResponse = await axios.get("/backend/config");
//                 setLlmUsed(configResponse.data.llmUsed || "Unknown");
//                 setTtsUsed(configResponse.data.ttsUsed || "Unknown");
//                 setSttUsed(configResponse.data.sttUsed || "Unknown");
//             } catch (error) {
//                 console.error("Error fetching configuration:", error);
//             }
//         };
    
//         fetchConfigAndId();
//     }, []);
    
    
//     const handleFeedbackSubmit = async () => {
//         if (!feedback.trim()) {
//             setResponseMessage("Please enter your feedback.");
//             return;
//         }

//         try {
//             const response = await axios.post("/backend/user-feedback", {
//                 feedback,
//                 conversation_id: conversationId,
//                 llmUsed,
//                 ttsUsed,
//                 sttUsed,
//             });
//             setResponseMessage(response.data.message || "Thank you for your feedback!");
//             setIsSubmitted(true);
//             setFeedback(""); // Clear feedback after successful submission
//         } catch (error: any) {
//             setResponseMessage(
//                 error.response?.data?.error || "Failed to submit feedback. Please try again."
//             );
//         }
//     };

//     const handleCancel = () => {
//         setShowFeedbackForm(false);
//         setFeedback("");
//         setResponseMessage("");
//         setIsSubmitted(false);
//     };

//     return (
//         <>
//             {/* Floating Button to Open Feedback Drawer */}
//             <button
//                 onClick={() => setShowFeedbackForm(true)}
//                 className="fixed bottom-4 right-4 px-4 py-2 bg-gray-800 text-white rounded-lg text-sm font-medium hover:bg-gray-700 z-50"
//             >
//                 Report Issue
//             </button>

//             {/* Slide-In Drawer */}
//             {showFeedbackForm && (
//                 <div className="fixed top-0 right-0 w-80 h-full bg-white shadow-lg z-50 p-6 transition-transform transform translate-x-0">
//                     <div className="flex justify-between items-center mb-4">
//                         <h3 className="text-xl font-semibold">Report Issue</h3>
//                         <button
//                             onClick={handleCancel}
//                             className="text-gray-500 hover:text-gray-700"
//                         >
//                             ✕
//                         </button>
//                     </div>

//                     {isSubmitted ? (
//                         <p className="text-green-600">{responseMessage}</p>
//                     ) : (
//                         <div className="space-y-4">
//                             <label className="block text-sm font-medium text-gray-700 mb-1">
//                                 Description
//                             </label>
//                             <textarea
//                                 value={feedback}
//                                 onChange={(e) => setFeedback(e.target.value)}
//                                 className="w-full p-2 border rounded-lg h-32 focus:outline-none focus:ring-2 focus:ring-[#800000]"
//                                 placeholder="Please describe the issue you encountered..."
//                             />
//                             {responseMessage && (
//                                 <p className="text-red-600">{responseMessage}</p>
//                             )}
//                             <div>
//                                 <p className="text-sm text-gray-600">LLM Used: {llmUsed}</p>
//                                 <p className="text-sm text-gray-600">TTS Used: {ttsUsed}</p>
//                                 <p className="text-sm text-gray-600">STT Used: {sttUsed}</p>
//                                 <p className="text-sm text-gray-600">Conversation ID: {conversationId}</p>
//                             </div>
//                             <div className="flex space-x-3">
//                                 <button
//                                     onClick={handleFeedbackSubmit}
//                                     className="flex-1 py-2 bg-[#800000] text-white rounded-lg font-medium hover:bg-[#600000]"
//                                 >
//                                     Submit
//                                 </button>
//                                 <button
//                                     onClick={handleCancel}
//                                     className="flex-1 py-2 bg-gray-100 text-gray-700 rounded-lg font-medium hover:bg-gray-200"
//                                 >
//                                     Cancel
//                                 </button>
//                             </div>
//                         </div>
//                     )}
//                 </div>
//             )}
//         </>
//     );
// };

// export default FeedbackDrawer;
import React, { useState, useEffect } from "react";
import axios from "axios";

interface FeedbackProps {
    conversationId: string;
}

const FeedbackDrawer: React.FC<FeedbackProps> = ({ conversationId = "Unknown" }) => {
    const [showFeedbackForm, setShowFeedbackForm] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<string>("");
    const [responseMessage, setResponseMessage] = useState<string>("");
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [llmUsed, setLlmUsed] = useState<string>("Unknown");
    const [ttsUsed, setTtsUsed] = useState<string>("Unknown");
    const [sttUsed, setSttUsed] = useState<string>("Unknown");

    useEffect(() => {
        const fetchConfigAndId = async () => {
            try {
                const configResponse = await axios.get("/backend/config");
                setLlmUsed(configResponse.data.llmUsed || "Unknown");
                setTtsUsed(configResponse.data.ttsUsed || "Unknown");
                setSttUsed(configResponse.data.sttUsed || "Unknown");
            } catch (error) {
                console.error("Error fetching configuration:", error);
            }
        };

        fetchConfigAndId();
    }, []);

    const handleFeedbackSubmit = async () => {
        if (!feedback.trim()) {
            setResponseMessage("Please enter your feedback.");
            return;
        }

        try {
            const response = await axios.post("/backend/user-feedback", {
                feedback,
                conversation_id: conversationId,
                llmUsed,
                ttsUsed,
                sttUsed,
            });
            setResponseMessage(response.data.message || "Thank you for your feedback!");
            setIsSubmitted(true);
            setFeedback(""); // Clear feedback after successful submission
        } catch (error: any) {
            setResponseMessage(
                error.response?.data?.error || "Failed to submit feedback. Please try again."
            );
        }
    };

    const handleCancel = () => {
        setShowFeedbackForm(false);
        setFeedback("");
        setResponseMessage("");
        setIsSubmitted(false);
    };

    return (
        <>
            {/* Floating Button to Open Feedback Drawer */}
            <button
                onClick={() => setShowFeedbackForm(true)}
                className="fixed bottom-6 right-6 px-4 py-2 bg-[#800000] text-white rounded-full text-sm font-semibold shadow-lg hover:bg-[#600000] transition duration-300"
            >
                Report Issue
            </button>

            {/* Slide-In Drawer */}
            {showFeedbackForm && (
                <div className="fixed top-0 right-0 w-80 h-full bg-gray-100 shadow-2xl z-50 p-6 transition-transform transform translate-x-0 rounded-l-2xl">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-xl font-semibold text-gray-800">Report Issue</h3>
                        <button
                            onClick={handleCancel}
                            className="text-gray-500 hover:text-gray-700 text-xl"
                        >
                            ✕
                        </button>
                    </div>

                    {isSubmitted ? (
                        <p className="text-green-600">{responseMessage}</p>
                    ) : (
                        <div className="space-y-4">
                            <label className="block text-sm font-medium text-gray-700">
                                Description
                            </label>
                            <textarea
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                className="w-full p-3 border border-gray-300 rounded-lg h-32 focus:outline-none focus:ring-2 focus:ring-[#800000] bg-white text-gray-800 shadow-sm"
                                placeholder="Please describe the issue you encountered..."
                            />
                            {responseMessage && (
                                <p className="text-red-600">{responseMessage}</p>
                            )}
                            <div className="mt-3 bg-gray-200 p-3 rounded-lg text-gray-700 space-y-1">
                                <p className="text-sm">LLM Used: {llmUsed}</p>
                                <p className="text-sm">TTS Used: {ttsUsed}</p>
                                <p className="text-sm">STT Used: {sttUsed}</p>
                                <p className="text-sm">Conversation ID: {conversationId}</p>
                            </div>
                            <div className="flex justify-between space-x-3 mt-4">
                                <button
                                    onClick={handleFeedbackSubmit}
                                    className="flex-1 py-2 bg-[#800000] text-white rounded-lg font-medium hover:bg-[#600000] transition duration-300"
                                >
                                    Submit
                                </button>
                                <button
                                    onClick={handleCancel}
                                    className="flex-1 py-2 bg-gray-300 text-gray-700 rounded-lg font-medium hover:bg-gray-400 transition duration-300"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default FeedbackDrawer;

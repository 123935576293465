import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Workflow } from "./types";
import { Header, MainLayout } from "./Layouts";

const getRandomValue = (type: string) => {
    const randomWords = ["Alpha", "Bravo", "Charlie", "Delta", "Echo", "Foxtrot", "Golf", "Hotel", "India", "Juliet"];
    switch (type) {
        case "text":
            return randomWords[Math.floor(Math.random() * randomWords.length)];
        case "number":
            return Math.floor(Math.random() * 10000);
        case "date":
            return new Date().toISOString().split("T")[0];
        case "time":
            return new Date().toTimeString().split(" ")[0].slice(0, 5); // HH:MM format
        case "currency":
            return "Rupees"; // Default currency value
        default:
            return "";
    }
};

const ContextForm: React.FC = () => {
    const selectedWorkflow: Workflow = useLocation().state?.selectedWorkflow as Workflow;
    let navigate = useNavigate();

    const [formData, setFormData] = useState(
        Object.fromEntries(selectedWorkflow.fields.map(field => [field.name, field.defaultValue ?? getRandomValue(field.type)]))
    );

    if (!selectedWorkflow) {
        console.log("No workflow selected. Redirecting to home page...");
        navigate("/");
        return null;
    }

    const handleChange = (key: string, value: string) => {
        setFormData(prev => ({ ...prev, [key]: value }));
    };

    return (
        <MainLayout>
            <Header />
            <div className="max-w-2xl mx-auto space-y-8">
                <h2 className="text-2xl font-bold text-gray-900">
                    {selectedWorkflow.display_name}
                </h2>
                <div className="space-y-6 bg-white p-6 rounded-lg shadow">
                {selectedWorkflow.fields.map((field) => (
                <div key={field.name}>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        {field.label}
                    </label>
                    {field.type === "text" || field.type === "number" ? (
                        <input
                            type={field.type}
                            value={formData[field.name]}
                            onChange={(e) => handleChange(field.name, e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            placeholder={`Enter ${field.label}`}
                        />
                    ) : field.type === "date" ? (
                        <input
                            type="date"
                            value={formData[field.name]}
                            onChange={(e) => handleChange(field.name, e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        />
                    ) : field.type === "time" ? (
                        <input
                            type="time"
                            value={formData[field.name]}
                            onChange={(e) => handleChange(field.name, e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        />
                    ) : field.type === "currency" ? (
                        <input
                            type="text"
                            value={formData[field.name]}
                            onChange={(e) => handleChange(field.name, e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            placeholder="$0.00"
                        />
                    ) : null}
                </div>
            ))}
                </div>
                <div className="text-center">
                    <button
                        onClick={() => navigate("/chat", { state: { selectedWorkflow, formData } })}
                        className="!rounded-button bg-blue-600 text-white px-8 py-3 text-lg font-medium"
                    >
                        Start Chat
                    </button>

                    <button
                        onClick={() => navigate("/chatv2", { state: { selectedWorkflow, formData } })}
                        className="!rounded-button bg-blue-600 text-white px-8 py-3 text-lg font-medium"
                    >
                        Start Chat v2
                    </button>
                    <button
                        onClick={() => navigate("/duplex", { state: { selectedWorkflow, formData } })}
                        className="!rounded-button bg-blue-600 text-white px-8 py-3 text-lg font-medium"
                    >
                        Duplex
                    </button>

                    <button
                        onClick={() => navigate("/duplexv2", { state: { selectedWorkflow, formData } })}
                        className="!rounded-button bg-blue-600 text-white px-8 py-3 text-lg font-medium"
                    >
                        Duplex V2
                    </button>
                </div>
            </div>
        </MainLayout>
    );
};

export default ContextForm;

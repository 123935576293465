import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Workflow } from './types';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const getRandomValue = (type: string) => {
    const randomWords = ["Alpha", "Bravo", "Charlie", "Delta", "Echo", "Foxtrot", "Golf", "Hotel", "India", "Juliet"];
    switch (type) {
        case "text":
            return randomWords[Math.floor(Math.random() * randomWords.length)];
        case "number":
            return Math.floor(Math.random() * 10000);
        case "date":
            return new Date().toISOString().split("T")[0];
        case "time":
            return new Date().toTimeString().split(" ")[0].slice(0, 5); // HH:MM format
        case "currency":
            return "Rupees"; // Default currency value
        default:
            return "";
    }
};

const HomeV2: React.FC = () => {
    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    let navigate = useNavigate();
    const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(null);
    const [isCallActive, setIsCallActive] = useState(false);
    const [formData, setFormData] = useState<{ [key: string]: any }>({});
    const [conversation, setConversation] = useState<Array<{ speaker: string; text: string }>>([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [selectedVoice, setSelectedVoice] = useState('Leela');
    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const [volume, setVolume] = useState(75);
    const [isSpeaking, setIsSpeaking] = useState<'bot' | 'human' | null>(null);
    const [showReportForm, setShowReportForm] = useState(false);
    const [reportDescription, setReportDescription] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const workflowFormData = useRef<{ [key: string]: { [key: string]: any } }>({});
    const auth = useContext(AuthContext);
    const sessionInfo = {
        conversationId: 'CONV-2025022806-124',
        llmUsed: 'GPT-4 Turbo',
        ttsUsed: 'Azure Neural TTS',
        sttUsed: 'Whisper Large v3'
    };
    const location = useLocation();
    console.log(location)

    useEffect(() => {
        fetch("/backend/get-workflows")
            .then((response) => response.json())
            .then((data) => {
                setWorkflows(data);
            })
            .catch((error) => console.error("Error fetching workflows:", error));
    }, []);

    useEffect(() => {
        if (isCallActive) {
            const interval = setInterval(() => {
                setIsSpeaking(prev => prev === 'bot' ? 'human' : 'bot');
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [isCallActive]);

    const toggleIssueSelect = (workflow: Workflow) => {
        if (workflow.id === selectedWorkflow?.id) {
            workflowFormData.current[workflow.id] = formData;
            setFormData({});
            setSelectedWorkflow(null);
            return;
        };
        setSelectedWorkflow(workflow);
        if (workflow) {
            if (workflowFormData.current[workflow.id]) {
                setFormData(workflowFormData.current[workflow.id]);
            }
            else {
                setFormData(Object.fromEntries(workflow.fields.map(field => [field.name, field.defaultValue ?? getRandomValue(field.type)])));
            }
        }
    };
    const handleInputChange = (field: string, value: string) => {
        if (formData) {
            // const newFields = { ...formData.fields, [field]: value };
            setFormData({ ...formData, [field]: value });
            setIsFormValid(Object.values(formData).every(v => String(v).trim() !== ''));
        }
    };
    const startCall = () => {
        navigate("/duplexv2", { state: { selectedWorkflow, formData } })
    };
    const endCall = () => {
        setIsCallActive(false);
        setSelectedWorkflow(null);
        setFormData({});
        setConversation([]);
        setIsSpeaking(null);
    };
    const AudioVisualizer = () => (
        <div className="bg-gray-800 p-4 rounded-lg mb-6">
            <div className="flex items-center justify-between mb-4">
                <div className="text-white text-lg">
                    {isSpeaking === 'bot' ? 'AI Assistant Speaking...' : 'User Speaking...'}
                </div>
                <div className="text-white text-sm">
                    {new Date().toLocaleTimeString()}
                </div>
            </div>
            <div className="flex items-center space-x-1 h-12 mb-4">
                {[...Array(20)].map((_, i) => (
                    <div
                        key={i}
                        className="w-2 bg-white rounded-full transition-all duration-150"
                        style={{
                            height: isSpeaking ? `${Math.random() * 100}%` : '10%',
                            opacity: isSpeaking ? 0.3 + Math.random() * 0.7 : 0.3
                        }}
                    />
                ))}
            </div>
            <div className="flex items-center justify-between">
                <button
                    onClick={() => setVolume(volume === 0 ? 75 : 0)}
                    className="w-10 h-10 flex items-center justify-center bg-white/20 rounded-full hover:bg-white/30 transition-all duration-300"
                >
                    <i className={`fas fa-volume-high text-white text-lg ${volume === 0 ? 'relative after:content-[""] after:absolute after:w-[2px] after:h-6 after:bg-white after:-rotate-45 after:left-1/2 after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2' : ''}`}></i>
                </button>
                <button
                    onClick={endCall}
                    className="w-10 h-10 flex items-center justify-center bg-red-600 rounded-full hover:bg-red-700 transition-all duration-300"
                >
                    <i className="fas fa-phone-slash text-white text-lg"></i>
                </button>
            </div>
        </div>
    );

    const handleLogout = () => {
        auth?.logout();
        navigate("/login", { replace: true });
    };
    return (
        <div className="relative min-h-screen min-w-[320px] max-w-[700px] bg-gray-900 font-sans mx-auto flex flex-col items-center">

            {/* Header */}
            {/* <div className="fixed top-0 w-full bg-gray-800 h-20 flex items-center justify-center px-4 z-50">
                <img src="https://static.readdy.ai/image/b96376c1db9f62c52da78c2504af8261/a78ea042d989ed85554932bc9e612c79.png" alt="Hyperface Logo" className="h-8 w-auto" />
            </div> */}
            {/* Main Content */}
            <div className="fixed top-0 w-full bg-gray-800 h-20 flex items-center justify-center px-4 z-50 relative">
                <img src="https://static.readdy.ai/image/b96376c1db9f62c52da78c2504af8261/a78ea042d989ed85554932bc9e612c79.png"
                    alt="Hyperface Logo" className="h-8 w-auto absolute left-1/2 transform -translate-x-1/2" />
                <div
                    onClick={handleLogout}
                    className="absolute right-4 text-blue-500 underline cursor-pointer transition-all duration-300 hover:text-red-700"
                >
                    <span className="text-sm font-medium">Logout</span>
                </div>


            </div>
            <div className="pt-28 pb-10 px-6 min-w-[320px] max-w-[500px] w-full min-h-[80vh]">
                {!isCallActive ? (
                    <>
                        <h2 className="text-xl font-semibold mb-6 text-white">Select Your Issue</h2>
                        <div className="space-y-3">
                            {workflows.map((workflow) => (
                                <div key={workflow.id} className="w-full">
                                    <button
                                        onClick={() => toggleIssueSelect(workflow)}
                                        className={`w-full p-4 rounded-lg flex items-center justify-between ${selectedWorkflow?.id === workflow.id ? 'bg-blue-600 text-white' : 'bg-gray-800 text-gray-200'
                                            } shadow-sm transition-all duration-300`}
                                    >
                                        <div className="flex items-center space-x-3">
                                            <i className={`fas ${workflow.icon} text-lg`}></i>
                                            <span className="font-medium text-base">{workflow.display_name}</span>
                                        </div>
                                        <i className={`fas fa-chevron-right transition-transform ${selectedWorkflow?.id === workflow.id ? 'rotate-90' : ''
                                            }`}></i>
                                    </button>
                                    {selectedWorkflow?.id === workflow.id && formData && (
                                        <div className="mt-3 p-4 bg-gray-800 rounded-lg shadow-sm text-gray-200">
                                            {workflow.fields.map((field) => (
                                                <div key={field.name} className="mb-4">
                                                    <label className="block text-lg font-medium text-gray-300 mb-1">
                                                        {field.label}
                                                    </label>
                                                    {field.type === "text" || field.type === "number" ? (
                                                        <input
                                                            type={field.type}
                                                            value={formData[field.name]}
                                                            onChange={(e) => handleInputChange(field.name, e.target.value)}
                                                            className="w-full p-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg bg-gray-700 text-white"
                                                            placeholder={`Enter ${field.label}`}
                                                        />
                                                    ) : field.type === "date" ? (
                                                        <input
                                                            type="date"
                                                            value={formData[field.name]}
                                                            onChange={(e) => handleInputChange(field.name, e.target.value)}
                                                            className="w-full p-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg bg-gray-700 text-white"
                                                        />
                                                    ) : field.type === "time" ? (
                                                        <input
                                                            type="time"
                                                            value={formData[field.name]}
                                                            onChange={(e) => handleInputChange(field.name, e.target.value)}
                                                            className="w-full p-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg bg-gray-700 text-white"
                                                        />
                                                    ) : field.type === "currency" ? (
                                                        <input
                                                            type="text"
                                                            value={formData[field.name]}
                                                            onChange={(e) => handleInputChange(field.name, e.target.value)}
                                                            className="w-full p-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg bg-gray-700 text-white"
                                                            placeholder="$0.00"
                                                        />
                                                    ) : null}
                                                </div>
                                            ))}
                                            {/* <div className="space-y-4 mt-6">
                                                <div>
                                                    <label className="block text-base font-medium text-gray-700 mb-2">Select AI Voice</label>
                                                    <div className="flex space-x-3">
                                                        {['Leela', 'Rahul'].map((voice) => (
                                                            <button
                                                                key={voice}
                                                                onClick={() => setSelectedVoice(voice)}
                                                                className={`px-4 py-2 rounded-lg text-lg ${selectedVoice === voice ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'
                                                                    }`}
                                                            >
                                                                {voice}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="block text-base font-medium text-gray-700 mb-2">Select Language</label>
                                                    <div className="flex space-x-3">
                                                        {['English', 'Hindi'].map((lang) => (
                                                            <button
                                                                key={lang}
                                                                onClick={() => setSelectedLanguage(lang)}
                                                                className={`px-4 py-2 rounded-lg text-lg ${selectedLanguage === lang ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'
                                                                    }`}
                                                            >
                                                                {lang}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className="space-y-4">
                        <AudioVisualizer />
                        <div className="flex justify-end items-center space-x-2 mb-4">
                            <div className="relative">
                                <button
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center"
                                >
                                    <i className="fas fa-info text-gray-600"></i>
                                </button>
                                {showTooltip && (
                                    <div className="absolute bottom-full right-0 mb-2 w-64 bg-white rounded-lg shadow-lg p-4 text-sm">
                                        <div className="space-y-2">
                                            <p><span className="font-medium">Conversation ID:</span> {sessionInfo.conversationId}</p>
                                            <p><span className="font-medium">LLM Used:</span> {sessionInfo.llmUsed}</p>
                                            <p><span className="font-medium">TTS Used:</span> {sessionInfo.ttsUsed}</p>
                                            <p><span className="font-medium">STT Used:</span> {sessionInfo.sttUsed}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <button
                                onClick={() => setShowReportForm(true)}
                                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg text-sm font-medium hover:bg-gray-200"
                            >
                                Report Issue
                            </button>
                        </div>
                        {showReportForm && (
                            <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 px-4">
                                <div className="bg-white rounded-lg w-full max-w-md p-6">
                                    <h3 className="text-xl font-semibold mb-4">Report Issue</h3>
                                    <div className="space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Conversation ID</label>
                                            <input type="text" value={sessionInfo.conversationId} disabled className="w-full p-2 bg-gray-50 rounded-lg border text-gray-500" />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">LLM Used</label>
                                            <input type="text" value={sessionInfo.llmUsed} disabled className="w-full p-2 bg-gray-50 rounded-lg border text-gray-500" />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">TTS Used</label>
                                            <input type="text" value={sessionInfo.ttsUsed} disabled className="w-full p-2 bg-gray-50 rounded-lg border text-gray-500" />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">STT Used</label>
                                            <input type="text" value={sessionInfo.sttUsed} disabled className="w-full p-2 bg-gray-50 rounded-lg border text-gray-500" />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                                            <textarea
                                                value={reportDescription}
                                                onChange={(e) => setReportDescription(e.target.value)}
                                                className="w-full p-2 border rounded-lg h-32 focus:outline-none focus:ring-2 focus:ring-[#800000]"
                                                placeholder="Please describe the issue you encountered..."
                                            />
                                        </div>
                                        <div className="flex space-x-3">
                                            <button
                                                onClick={() => {
                                                    // Handle submit logic here
                                                    setShowReportForm(false);
                                                    setReportDescription('');
                                                }}
                                                className="flex-1 py-2 bg-[#800000] text-white rounded-lg font-medium hover:bg-[#600000]"
                                            >
                                                Submit
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setShowReportForm(false);
                                                    setReportDescription('');
                                                }}
                                                className="flex-1 py-2 bg-gray-100 text-gray-700 rounded-lg font-medium hover:bg-gray-200"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {conversation.map((msg, index) => (
                            <div
                                key={index}
                                className={`flex ${msg.speaker === 'bot' ? 'justify-start' : 'justify-end'}`}
                            >
                                <div
                                    className={`max-w-[80%] p-3 rounded-lg text-lg ${msg.speaker === 'bot'
                                        ? 'bg-gray-800 text-gray-200'
                                        : 'bg-blue-600 text-white'
                                        }`}
                                >
                                    {msg.text}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {/* Call Control Button */}
            {!isCallActive && selectedWorkflow && (
                <div className="sticky bottom-0 w-full bg-gray-900 py-4 flex justify-end px-6 bg-transparent">
                    <button
                        onClick={startCall}
                        className="w-14 h-14 rounded-full flex items-center justify-center text-white shadow-lg bg-green-800 transition-all duration-300 hover:scale-110"
                    >
                        <i className="fas fa-phone text-xl"></i>
                    </button>
                </div>
            )}

        </div>
    );
};
export default HomeV2
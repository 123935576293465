import React, { useEffect, useRef } from 'react';
import { ProcessingMessageProps } from './types';
import { Message } from '../types';
interface MessageProps {
    message: Message;
    handleAudionEnd: () => void;
    isMuted: boolean;
}



const ProcessingMessageComponent: React.FC<ProcessingMessageProps> = ({ sender }) => {
  const isUser = sender === 'user';

  return (
    <div className={`flex ${isUser ? 'flex-row-reverse' : ''} items-end gap-2`}>
      {/* Sender icon */}
      <div className={`w-8 h-8 rounded-full ${isUser ? 'bg-blue-500/50' : 'bg-gray-500/50'} flex items-center justify-center`}>
        <span className="text-sm font-medium text-gray-300">{isUser ? 'You' : 'AI'}</span>
      </div>

      {/* Processing message bubble */}
      <div className={`max-w-[70%] ${isUser ? 'bg-blue-500/20 rounded-2xl rounded-br-none' : 'bg-gray-500/20 rounded-2xl rounded-bl-none'} backdrop-blur-sm p-3 animate-pulse`}>
        <p className="text-gray-400 text-base">Processing...</p>
      </div>
    </div>
  );
};

const MessageComponent: React.FC<MessageProps> = ({message, handleAudionEnd, isMuted}) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const isUser = message.sender === 'user';
      useEffect(() => {
        if (audioRef.current) {
            audioRef.current.muted = isMuted;
        }
    }, [isMuted]);
    return (
        <div className={`flex ${isUser ? 'flex-row-reverse' : ''} items-end gap-2`}> 
            <div className={`w-8 h-8 rounded-full ${isUser ? 'bg-blue-500' : 'bg-green-500'} flex items-center justify-center`}>
                <span className="text-sm font-medium">{message.sender.charAt(0)}</span>
            </div>
            <div className={`max-w-[70%] ${isUser ? 'bg-blu</div>e-500/20 rounded-2xl rounded-br-none' : 'bg-green-500/20 rounded-2xl rounded-bl-none'} backdrop-blur-sm p-3`}>
                <p className="text-gray-200 text-base">{message.text}</p>
            </div>
            <audio
                  id={`audio-${message.id}`}
                  ref={audioRef}
                  src={message.audioUrl}
                  controls
                  className="hidden"
                  autoPlay={false}
                  onEnded={handleAudionEnd}
                  // onEnded=
                />
        </div>
    );
};

export { MessageComponent, ProcessingMessageComponent };

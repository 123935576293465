import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

// Header Component
const Header = () => {
    const navigate = useNavigate();
    return (
        <header className="bg-white shadow-sm">
            <div className="max-w-7xl mx-auto px-4 py-4">
                <div className="flex items-center justify-between">
                    <h1 className="text-2xl font-semibold text-gray-900">AI Assistant</h1>
                    <button
                        onClick={() => navigate(-1)} // Navigates to the previous page
                        className="!rounded-button text-gray-600 hover:text-gray-900"
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} />
                        Back
                    </button>
                </div>
            </div>
        </header>
    );
};

// Main Layout Component

const MainLayout = ({ children }: { children: ReactNode }) => (
    <div className="min-h-screen bg-gray-50">
        <main className="max-w-7xl mx-auto px-4 py-8">{children}</main>
    </div>
);

export { Header, MainLayout };
import { createContext, useState, useEffect, ReactNode } from "react";

interface AuthContextType {
    isAuthenticated?: boolean | null;
    login: (username: string, password: string) => Promise<boolean>;
    logout: () => void;
    checkAuth: () => Promise<boolean>;
}

export const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

    useEffect(() => {
        const checkAuth = async () => {
            const response = await fetch("backend/check-auth", {
                credentials: "include",
            });
            setIsAuthenticated(response.ok);
        };
        checkAuth();
    }, []);

    const checkAuth = async () => {
        const response = await fetch("backend/check-auth", {
            credentials: "include",
        });
        setIsAuthenticated(response.ok);
        return response.ok;
    };
    const login = async (username: string, password: string): Promise<boolean> => {
        const response = await fetch("backend/api-login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({ username, password }),
        });
        console.log("Response: ", response);
        if (response.ok) {
            setIsAuthenticated(true);
            return true;
        }
        return false;
    };

    const logout = async () => {
        await fetch("backend/logout", {
            method: "POST",
            credentials: "include",
        });
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, checkAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

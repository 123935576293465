import React, { useState, useRef, useEffect } from 'react';
import './index.css';
import { Workflow, Message } from './types';
import { useNavigate } from 'react-router-dom';
import { Header, MainLayout } from './Layouts';

const Home: React.FC = () => {
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  let navigate = useNavigate();
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetch("/backend/get-workflows")
      .then((response) => response.json())
      .then((data) => {
        setWorkflows(data);
      })
      .catch((error) => console.error("Error fetching workflows:", error));
  }, []);

  const handleWorkflowSelect = (workflow: Workflow) => {
    navigate('/form', { state: { selectedWorkflow: workflow } });
  };

  return (
    <MainLayout>
      <Header />
      <main className="max-w-7xl mx-auto px-4 py-8">
          <div className="space-y-8">
            <h2 className="text-3xl font-bold text-center text-gray-900">
              Select Your Workflow
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {workflows.map((workflow) => (
                <div
                  key={workflow.id}
                  className={`bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-transform hover:scale-105 `}
                  onClick={() => handleWorkflowSelect(workflow)}
                >
                  <div className="h-48 overflow-hidden">
                    <img
                      src={workflow.image_url || 'https://via.placeholder.com/150'}
                      alt={workflow.display_name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="p-6">
                    <h3 className="text-xl font-semibold mb-2">{workflow.display_name}</h3>
                    <p className="text-gray-600">{workflow.description || 'No description available'}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
      </main>
    </MainLayout>
  );
};

export default Home;

import React, { useState, useEffect, useRef } from "react";
import * as echarts from "echarts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faCross, faMicrophone, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { MicVAD, utils } from "@ricky0123/vad-web";
import { Message, Workflow } from "../types";
import { useLocation,useNavigate } from "react-router-dom";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";




const MainCommunication: React.FC = () => {
  const workflow: Workflow = useLocation().state?.selectedWorkflow as Workflow;
  const data: any = useLocation().state?.formData;

  const [isRecording, setIsRecording] = useState(true);
  const [audioLevel, setAudioLevel] = useState(0);
  const [agentAudioLevel, setAgentAudioLevel] = useState(0);
  const [isAgentSpeaking, setIsAgentSpeaking] = useState(false);
  const [isAgentProcessing, setIsAgentProcessing] = useState(false);
  const [isInitialised, setIsInitialised] = useState(false);
  const [isAgentAudioPlaying, setIsAgentAudioPlaying] = useState(false);
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [userAudioLevelData, setUserAudioLevelData] = useState<Array<number>>([]);
  const [conversationId, setConversationId] = useState("");
  const [processingInputType, setProcessingInputType] = useState<"user" | "agent">("agent"); // Add isProcessing state
  const [isEnd, setIsEnd] = useState(false);
  const waveChartRef = useRef<HTMLDivElement>(null);
  const speechTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const vadRef = useRef<MicVAD | null>(null);
  const latestRequestIdRef = useRef<string>("");
  const navigate = useNavigate();
  

  // Feedback Icon - Always Visible
const feedbackIcon = (
  
  <div
    style={{
      position: "fixed",
      bottom: "20px",
      right: "20px",
      cursor: "pointer",
      zIndex: 1000,
      backgroundColor: "#007bff",
      color: "white",
      padding: "10px",
      borderRadius: "50%",
      boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
      transition: "transform 0.2s, background-color 0.2s",
    }}
    onClick={() => navigate("/feedback")}
    title="Give Feedback"
    aria-label="Feedback"
    onMouseEnter={(e) => {
      e.currentTarget.style.backgroundColor = "#0056b3";
      e.currentTarget.style.transform = "scale(1.1)";
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.backgroundColor = "#007bff";
      e.currentTarget.style.transform = "scale(1)";
    }}
  >
    <FontAwesomeIcon icon={faCommentDots} size="2x" />
  </div>
);



  const simulateAgentConversation = () => {
    setAgentAudioLevel(prevLevel => {
      if (!isAgentAudioPlaying) {
        return 0; // Stop audio level if not playing
      }
      const value = Math.random() + 0.1; // Random value between 0.1 and 1.1
      return value;
    });
  };

  useEffect(() => {
    const interval = setInterval(simulateAgentConversation, 200);
    return () => clearInterval(interval); // Cleanup on unmount
  }, [isAgentAudioPlaying]);

  useEffect(() => {
    async function initVAD() {
      try {
        vadRef.current = await MicVAD.new({
          model: "v5",
          //   positiveSpeechThreshold: 0.4,
          positiveSpeechThreshold: 0.5,
          negativeSpeechThreshold: 0.35,
          //   minSpeechFrames: 15,
          minSpeechFrames: 15,
          preSpeechPadFrames: 30,
          onSpeechStart: () => {
            console.log("SPEECH START")
            speechTimeoutRef.current = setTimeout(() => {
              handleSpeechStart();
            }, 1250);
          },
          onFrameProcessed: (probabilities, frame) => {
            setAudioLevel(probabilities.isSpeech);
            setUserAudioLevelData(Array.from(frame))
          },
          onVADMisfire() {
            console.log("VAD Misfire");
            if (speechTimeoutRef.current) {
              clearTimeout(speechTimeoutRef.current);
              speechTimeoutRef.current = null; // Clear reference
            }
            // Clear vad misfire
          },
          onSpeechEnd: (audio) => {
            const wavBuffer = utils.encodeWAV(audio);
            const audioBlob = new Blob([wavBuffer], { type: "audio/wav" });
            handleSendAudioMessage(audioBlob);
          },
        });
      } catch (e) {
        console.error("VAD initialization failed:", e);
      }
    }
    initVAD();
  }, []);

  const stopAllPlaying = () => {
    const audioElements = document.getElementsByTagName("audio");
    setIsAgentAudioPlaying(false);
    for (let i = 0; i < audioElements.length; i++) {
      audioElements[i].pause();
      // audioElements[i].currentTime = 0;
    }
  };

  const handleSpeechStart = async () => {
    console.log("Handle speech start")
    stopAllPlaying();
  }



  // const handleStartRecording = (data) => {
  //   setIsRecording(true);
  //   handleInitialMessage(data);
  //   // simulateConversation();
  // };

  // const simulateConversation = () => {
  //   setIsInitialised(true);
  //   const audioTimer = setInterval(() => {
  //     setAudioLevel(Math.random());
  //   }, 100);

  //   setTimeout(() => {
  //     setMessages((prev) => [...prev, { type: "user", text: "Hi, I need help with setting up my new device." }]);
  //     setIsAgentProcessing(true);
  //     setTimeout(() => {
  //       setIsAgentProcessing(false);
  //       setIsAgentSpeaking(true);
  //       setMessages((prev) => [...prev, { type: "agent", text: "Hello! I'd be happy to help you with setting up your device. Could you please tell me what type of device you're trying to set up?" }]);
  //       setTimeout(() => {
  //         setIsAgentSpeaking(false);
  //       }, 3000);
  //     }, 2000);
  //   }, 1500);
  // };


  const handleInitialMessage = async (props: any) => {
    setIsInitialised(true);
    setIsAgentProcessing(true);
    props["workflow_id"] = workflow.id;
    try {
      const response = await fetch("/backend/start", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(props),
        credentials: "include",
        mode: 'cors'
      });
      console.log(response);

      if (!response.ok) throw new Error("Failed to fetch initial message");

      const agentResponse = await response.json();
      const conv_id = response.headers.get("x-conversation-id");
      setConversationId(conv_id || "");

      const receivedMessage: Message = {
        id: Date.now().toString(),
        text: agentResponse.message,
        sender: "agent",
        timestamp: new Date(),
        audioUrl: agentResponse.response_audio_url || "",
      };
      setIsAgentProcessing(false);
      setMessages([receivedMessage]);
      playNewlyCreatedMessage(receivedMessage);
      if (vadRef.current) {
        vadRef.current.start();
      }
      await ackMessage(agentResponse.id);
    } catch (error) {
      console.error("Error fetching initial message:", error);
    }
  };


  const handleSendAudioMessage = async (audioBlob: Blob) => {
    setProcessingInputType("user");
    setIsAgentProcessing(true);

    const formData = new FormData();
    formData.append("audio", audioBlob);

    try {

      const response = await fetch("/backend/user-audio-transcribe", {
        method: "POST",
        body: formData,
        credentials: "include",
        mode: 'cors'
      });

      if (!response.ok) throw new Error("Failed to fetch agent response");

      const jsonResponse = await response.json();

      handleSendTextMessage(jsonResponse.transcript, jsonResponse.audio_url);
    } catch (error) {
      console.error("Error processing audio message:", error);
      setIsAgentProcessing(false);
    }
  };

  const handleSendTextMessage = async (text: string, audio_url?: string) => {
    setProcessingInputType("user");
    setIsAgentProcessing(true);
    const requestId = Date.now().toString();
    latestRequestIdRef.current = requestId;
    console.log(requestId);

    const sentMessage: Message = {
      id: Date.now().toString(),
      text: text,
      sender: "user",
      audioUrl: audio_url || "",
      timestamp: new Date(),
    };

    setMessages((prev) => [...prev, sentMessage]);
    // setInputText("");
    setProcessingInputType("agent");

    try {
      const response = await fetch("/backend/chat", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_input: text, audio_url: audio_url }),
        credentials: "include",
        mode: 'cors'
      });

      if (!response.ok) throw new Error("Failed to fetch agent response");



      const agentResponse = await response.json();
      const conv_id = response.headers.get("x-conversation-id");
      setConversationId(conv_id || "");

      if (latestRequestIdRef.current !== requestId) {
        console.log(requestId, latestRequestIdRef.current);
        console.log("Received response for outdated request. Ignoring...");
        return;
      }

      const receivedMessage: Message = {
        id: Date.now().toString(),
        text: agentResponse.message,
        sender: "agent",
        timestamp: new Date(),
        audioUrl: agentResponse.response_audio_url || "",
      };
      setMessages((prev) => [...prev, receivedMessage]);
      if (agentResponse.is_end) {
        vadRef.current?.destroy();
        stopAllPlaying();
        setIsEnd(true);
      }

      playNewlyCreatedMessage(receivedMessage);
      await ackMessage(agentResponse.id);
      setIsAgentProcessing(false);

    } catch (error) {
      console.error("Error fetching agent response:", error);
      setIsAgentProcessing(false);
    }
  };

  const playNewlyCreatedMessage = (message: Message) => {
    console.log("Playing new message");
    stopAllPlaying();
    if (message.audioUrl) {
      setTimeout(() => {
        setIsAgentAudioPlaying(true);
        const audioElement = document.getElementById(`audio-${message.id}`) as HTMLAudioElement;
        if (audioElement) {
          const playPromise = audioElement.play();
          if (playPromise !== undefined) {
            playPromise.catch(error => console.warn("Autoplay blocked:", error));
          }
        }
      }, 100);
    }
  }

  const ackMessage = async (id: string) => {
    const tempResponse = await fetch("/backend/update-msg-status", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ message_id: id }),
      credentials: "include",
      mode: 'cors'
    });
    console.log(tempResponse);
  };



  if (!isInitialised) {
    setIsInitialised(true);
    setIsRecording(true);
    handleInitialMessage(data);
    // handleStartRecording();
  }

  return (
    <div className="relative w-full p-4 sm:p-8">
      {isEnd && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/50 z-40">
          <button className="px-8 sm:px-12 py-4 sm:py-6 bg-[#4A4AFF] hover:bg-[#3A3AFF] transition-colors duration-300 text-lg sm:text-xl font-semibold rounded-full flex items-center gap-2 sm:gap-3">
            <FontAwesomeIcon icon={faCirclePlay} className="text-xl sm:text-2xl" />
            Chat Ended
          </button>
        </div>
      )}
      <div className="flex flex-col sm:flex-row w-full">
        <div className="w-full sm:w-[60%] flex flex-col items-center justify-center relative">
          <div className="mb-12 sm:mb-16">
            {!isRecording ? (
              <button className="w-24 h-24 sm:w-32 sm:h-32 rounded-full bg-[#1A1A1F] border-2 border-[#4A4AFF] flex items-center justify-center transition-all duration-300 hover:scale-105">
                <FontAwesomeIcon icon={faMicrophone} className="text-2xl sm:text-3xl" />
              </button>
            ) : (
              <div
                className={`w-24 h-24 sm:w-32 sm:h-32 rounded-full bg-[#1A1A1F] border-2 ${
                  audioLevel > 0.5 ? "border-[#00FF94]" : "border-[#4A4AFF]"
                } flex items-center justify-center relative`}
              >
                <div
                  className="absolute w-full h-full rounded-full bg-[#4A4AFF] opacity-20 transition-transform duration-200"
                  style={{ transform: `scale(${0.8 + audioLevel * 0.4})` }}
                />
                <FontAwesomeIcon icon={faMicrophone} className="text-2xl sm:text-3xl" />
              </div>
            )}
          </div>
          <div className="h-16 sm:h-24 mb-12 sm:mb-16">
            {isAgentProcessing && (
              <div className="flex space-x-2">
                <div className="w-2 h-2 sm:w-3 sm:h-3 bg-[#4A4AFF] rounded-full animate-bounce" />
                <div className="w-2 h-2 sm:w-3 sm:h-3 bg-[#4A4AFF] rounded-full animate-bounce [animation-delay:0.2s]" />
                <div className="w-2 h-2 sm:w-3 sm:h-3 bg-[#4A4AFF] rounded-full animate-bounce [animation-delay:0.4s]" />
              </div>
            )}
          </div>
          <div
            className={`w-24 h-24 sm:w-32 sm:h-32 rounded-full bg-[#1A1A1F] border-2 ${
              agentAudioLevel > 0.2 ? "border-[#00FF94]" : "border-[#4A4AFF]"
            } flex items-center justify-center relative`}
          >
            <div
              className="absolute w-full h-full rounded-full bg-[#4A4AFF] opacity-20 transition-transform duration-200"
              style={{ transform: `scale(${0.8 + agentAudioLevel * 0.4})` }}
            />
            <FontAwesomeIcon icon={faVolumeUp} className="text-2xl sm:text-3xl" />
          </div>
        </div>
        <div className="h-12 sm:h-0" /> {/* Simple padding element below */}
        <div className="w-full sm:w-[40%] bg-[#1A1A1F] p-4 sm:p-6 overflow-y-auto h-[50vh] sm:h-auto">
          <div className="flex flex-col space-y-3 sm:space-y-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`max-w-[90%] sm:max-w-[80%] p-3 sm:p-4 rounded-lg ${
                  message.sender === "user"
                    ? "bg-[#2C2C3A] ml-auto"
                    : "bg-[#242428]"
                }`}
              >
                <div className="text-xs sm:text-sm text-[#808080] mb-1">
                  {message.sender === "user" ? "You" : "Assistant"}
                </div>
                <div className="text-[#E0E0E0] text-sm sm:text-base">{message.text}</div>
                <audio
                  id={`audio-${message.id}`}
                  src={message.audioUrl}
                  controls
                  className="hidden"
                  onEnded={() => {
                    setIsAgentAudioPlaying(false);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Feedback Icon */}
      <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-[#4A4AFF] p-2 rounded-full shadow-lg">
      {feedbackIcon}
    </div>

    </div>
    
  );
  
  
};

export default MainCommunication;

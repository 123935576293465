import { useContext } from "react";
import { AuthContext } from "./AuthContext";

const Logout = () => {
    const auth = useContext(AuthContext);
    if (!auth) return null;

    const { logout } = auth;

    return <button onClick={logout}>Logout</button>;
};

export default Logout;

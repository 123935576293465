import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import MainCommunication from "./MainCommunication";

const DuplexPage: React.FC = () => {
  const [isStarted, setIsStarted] = useState(false);

  return (
    <div className="min-h-screen bg-[#121214] text-white relative">
      {!isStarted ? (
        <div className="absolute inset-0 flex items-center justify-center bg-[#121214] z-50">
          <button
            onClick={() => setIsStarted(true)}
            className="px-12 py-6 bg-[#4A4AFF] hover:bg-[#3A3AFF] transition-colors duration-300 text-xl font-semibold rounded-full flex items-center gap-3"
          >
            <FontAwesomeIcon icon={faPlayCircle} className="text-2xl" />
            Start Voice Assistant
          </button>
        </div>
      ) : (
        <div className="flex h-screen">
          <MainCommunication />
        </div>
      )}
    </div>
  );
};

export default DuplexPage;

import { BrowserRouter as Router, Routes, Route, Link, Navigate, useLocation } from "react-router-dom";
import Home from "./Home";
import ChatComponent from "./ChatComponent";
import ContextForm from "./ContextForm";
import ChatComponentv2 from "./ChatComponentv2";
import DuplexPage from "./Duplex/DuplexPage";
import DuplexV2 from "./DuplexV2/DuplexV2";
import HomeV2 from "./HomeV2";
import { JSX, useContext } from "react";
import { AuthContext } from "./AuthContext";
import Login from "./Login";
import Logout from "./Logout";
import Feedback from "./Feedback";


const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const auth = useContext(AuthContext);
    const location = useLocation();
    console.log(location, "Private Route")

    if (!auth) return null;
    if (auth.isAuthenticated === null) {
        return <div>Loading...</div>; // Prevents flickering
    }
    return auth.isAuthenticated ? children : <Navigate to="/login" state={{ from: location }}  />;
};

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/home-v1-old" element={<PrivateRoute><Home /></PrivateRoute>} />
                <Route path="/chat" element={<PrivateRoute><ChatComponent /></PrivateRoute>} />
                <Route path="/chatv2" element={<PrivateRoute><ChatComponentv2 /></PrivateRoute>} />
                <Route path="/form" element={<PrivateRoute><ContextForm /></PrivateRoute>} />
                <Route path="/duplex" element={<PrivateRoute><DuplexPage /></PrivateRoute>} />
                <Route path="/duplexv2" element={<PrivateRoute><DuplexV2 /></PrivateRoute>} />
                <Route path="/feedback" element={<Feedback conversationId="Unknown" />} />
                <Route path="/" element={<PrivateRoute><HomeV2 /></PrivateRoute>} />
            </Routes>
        </Router>
    );
}

export default App;

import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

const Login = () => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    useEffect(() => {
        if (auth?.isAuthenticated) {
            navigate("/", { replace: true });
        }
    }, [auth?.isAuthenticated, navigate]);

    if (!auth) return null;

    const handleLogin = async () => {
        const success = await auth.login(username, password);
        console.log(auth.isAuthenticated)
        if (success) {
            navigate(from, { replace: true });
        } else {
            setError("Invalid credentials");
        }
    };

    return (
        <div className="relative min-h-screen min-w-[320px] max-w-[700px] bg-gray-900 font-sans mx-auto flex flex-col items-center justify-center">
            <div className="fixed top-0 w-full bg-gray-800 h-20 flex items-center justify-center px-4 z-50">
                <img src="https://static.readdy.ai/image/b96376c1db9f62c52da78c2504af8261/a78ea042d989ed85554932bc9e612c79.png" 
                     alt="Hyperface Logo" className="h-8 w-auto" />
            </div>
            <div className="pt-28 pb-10 px-6 min-w-[320px] max-w-[500px] w-full flex flex-col items-center">
                <h2 className="text-xl font-semibold mb-6 text-white">Login</h2>
                <input 
                    type="text" 
                    placeholder="Username" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-white focus:ring-2 focus:ring-blue-500 mb-4" 
                />
                <input 
                    type="password" 
                    placeholder="Password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-white focus:ring-2 focus:ring-blue-500 mb-4" 
                />
                <button 
                    onClick={handleLogin} 
                    className="w-full p-3 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition">
                    Login
                </button>
                {error && <p className="text-red-500 mt-4">{error}</p>}
            </div>
        </div>
    );
};

export default Login;